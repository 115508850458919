import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { font, colors } from '../consts/style';

const Wrapper = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  color: white;
  background: ${colors.teal};
  h1 {
    ${font.h1};
  }
  a {
    color: white;
  }
`;

const NotFoundPage = () => (
  <Fragment>
    <Wrapper>
      <h1>404</h1>
      <Link to="/">Go home</Link>
    </Wrapper>
  </Fragment>
);

export default NotFoundPage;
